import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Spindleについて" mdxType="SEO" />
    <PageTitle title="Spindleについて" enTitle="About Spindle" mdxType="PageTitle" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/779a20b4134f1e8242c777a3c970c01b/87a80/about-index.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABW0lEQVQoz5VSy07CQBTtf/kDLty4c+MPuK4LTGRjghsVlahUsDExkjaWSFsDCQuRSKJR1CIrbCkP05hSulDUQDsP0wwpFV/xLubO3Jxzz7mZS+FAQATxf4Lybwih36EDdzCG8cgIe6XNIhPJr22X2K8WyDNTzd48KkEZimQHuHNH9MT65LwYBhD4HX2ooIgPHU2q5uqW7hcpkoznp3B2mb/LhLMR86UzNoViyL2+fXDN18yG1i2ZPZUAKGLponE1nZy9bJSnmJnbdiXoHHnhZO5PdLuZViTVIkw8Urbe7cV8ZPV8K5RbMl+toDLx3wcDvnLceet+mhljXCgUuEOOk/hdYY/l9ounZ6rmtYdwKK7VNduy6229ptZazZbruiOyLMs0Tcc2YtGVaHwnHloIpVIpQiYioigmkom0IDBxhmVZwzCGM499JgAAIUTO0VdB6DjO90vy53r8tGEfZBZb2IospywAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/779a20b4134f1e8242c777a3c970c01b/2aaaf/about-index.webp 160w", "/static/779a20b4134f1e8242c777a3c970c01b/85e47/about-index.webp 320w", "/static/779a20b4134f1e8242c777a3c970c01b/75198/about-index.webp 640w", "/static/779a20b4134f1e8242c777a3c970c01b/691bc/about-index.webp 960w", "/static/779a20b4134f1e8242c777a3c970c01b/7f8f9/about-index.webp 973w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/779a20b4134f1e8242c777a3c970c01b/69538/about-index.png 160w", "/static/779a20b4134f1e8242c777a3c970c01b/72799/about-index.png 320w", "/static/779a20b4134f1e8242c777a3c970c01b/6af66/about-index.png 640w", "/static/779a20b4134f1e8242c777a3c970c01b/d9199/about-index.png 960w", "/static/779a20b4134f1e8242c777a3c970c01b/87a80/about-index.png 973w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/779a20b4134f1e8242c777a3c970c01b/6af66/about-index.png",
              "alt": "AmebaのロゴとSpindleのロゴが横並びになっている。",
              "title": "AmebaのロゴとSpindleのロゴが横並びになっている。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Spindle(スピンドル)は「`}<strong parentName="p">{`Amebaらしさ`}</strong>{`」を一貫してユーザーに届けるための仕組みです。「Amebaらしさ」がユーザーに届き、共感が生まれることで、サービスの信頼へとつながります。`}</p>
    <p>{`またSpindleは`}<strong parentName="p">{`すべきこと`}</strong>{`と`}<strong parentName="p">{`しないこと`}</strong>{`の判断の指針であり、Amebaをつくるすべての人のために存在します。目に見えるもの、見えないものも、Amebaを通じてユーザーに届く全てのものによって「Amebaらしさ」がつくられることを意識しなければなりません。`}</p>
    <p>{`人と情報をつなぎ、暮らしと心を豊かにする場所を提供し続けるために、Spindleを軸にして「Amebaらしさ」をつくります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/187fa/about-concept.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGklEQVQoz3VSz2vTcBTPf+BR9ODFg7KD0yrDoW4dgyG1oJcWUQ+bqJtYBkWdeJhTlOqYuuIGro5ZkI3OdYIThtINOou1qCixiS3TlmF/pWnTtMmaNGny/T7pIsWL7/Te4/Ph8/h8HgH/lKKpqqY2R4wxAHxNk3fejbpWxhfJNz+YeLHKIYwAAGFECJJY1zUDnRMzGSFl0AxmXmDbHnfvvneg48mJdnfPoUddFo/9sv/q+0QYAAhOKKn1v2pUko4maQDQNE1RFKShVCnTOnbM4R+KJL68pVbnPvsDdNAyZd92c9da4gORYtJVuYp1nOfZ41M2i8fOVgqAACFkiJ+cOTPw0kmlY6ux4DIViKZjzyOz5gnrqZlzBMsXJEUGBFkut891xHS/M8szACBubgqiCAAX5gcv+gbJ3/RKPBhOfHIHn95Yum339rW7e4iKWKmpNaShHM+aXOaDd7voRLxSFmiaJr+TgOHs7KW+uSuh9Y/L0UBoPTK0NLLj1t7tw3sGFpwEw+clRcI6zpaY/aMdbePdhSpneKajhqv9C87+eWeOz5Mp6trrYeuz0ztHWswT1l/FJCHLslqvAwAvlVvHjpoedha3yAgjgxxnfzoWr3tC3unwi8m1aZu397zPUayWGm4XyqmaKgGAjvQkt5HkNnSkN6M2IqVyMZu3t+XBYfOk1fftlXFXI2eENbyF+F81X4KXyrV6zeiN5R/bl99KcFfdPwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/52ded4f6325b8e1d3ea9a3edf86f7a48/2aaaf/about-concept.webp 160w", "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/85e47/about-concept.webp 320w", "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/75198/about-concept.webp 640w", "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/691bc/about-concept.webp 960w", "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/d9b8e/about-concept.webp 1194w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/52ded4f6325b8e1d3ea9a3edf86f7a48/69538/about-concept.png 160w", "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/72799/about-concept.png 320w", "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/6af66/about-concept.png 640w", "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/d9199/about-concept.png 960w", "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/187fa/about-concept.png 1194w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/52ded4f6325b8e1d3ea9a3edf86f7a48/6af66/about-concept.png",
              "alt": "Amebaを作る様々な人（プロデューサー、デザイナー、エンジニアなど）が、Spindleという仕組みを通じて、「Ameba」という1つのサービスに収束することを示す図。Spindleは「Amebaらしさ」を体現し、その具体的な要素として10個の項目（ブランドガイドライン、デザイン原則、パターンライブラリ、ボイス&トーン、スタイルガイド、パフォーマンスガイドライン、ビジュアルアイデンティティ、アクセシビリティガイドライン、イラストシステム、UXライティングガイドライン）が列挙されている。",
              "title": "Amebaを作る様々な人（プロデューサー、デザイナー、エンジニアなど）が、Spindleという仕組みを通じて、「Ameba」という1つのサービスに収束することを示す図。Spindleは「Amebaらしさ」を体現し、その具体的な要素として10個の項目（ブランドガイドライン、デザイン原則、パターンライブラリ、ボイス&トーン、スタイルガイド、パフォーマンスガイドライン、ビジュアルアイデンティティ、アクセシビリティガイドライン、イラストシステム、UXライティングガイドライン）が列挙されている。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`SpindleにはAmebaを作るすべての人が「Amebaらしさ」をつくるための約束事や、それを手助けするツールやガイドラインが揃っています。`}</p>
    <h2 {...{
      "id": "spindleの由来",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#spindle%E3%81%AE%E7%94%B1%E6%9D%A5",
        "aria-label": "spindleの由来 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Spindleの由来`}</h2>
    <p>{`Spindleには次の意味があります。`}</p>
    <ul>
      <li parentName="ul">{`糸をつむぐための道具（紡錘）`}</li>
      <li parentName="ul">{`細胞分裂に必要な装置（紡錘体）`}</li>
    </ul>
    <p>{`「生きたコンテンツをつむぐ」というブランドコンセプトを体現する道具として、生物が成長するようにサービスを成長させるための装置として、Amebaに関わる人たちを後押しできるような存在になれるよう名付けました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f5d4a40201e90b31c253505480b6ddb9/6569d/about-logo-blueprint.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9klEQVQoz42SjW6DMAyEef9XREJDECAgUAh0oeTnqxLWjqrbOkuWL5Fz9tnJQggkCwEPD3yE7/jO73lZhN4HHKDWlfXTYMOJ7ET8jjxaFp9EOGmNEIKqrlnN9uj0nuicw1rLX5Y6VAHm7UrbdbRti5SSVkr8qatI1HUdwzCgtWYcR5RSzPPMNE30fc+yLEeHewAbApNSNELQNA36cnmSum0bVVWlgpEoz3OKoki58b4sy4S/JIPzHmMdQ6ysFzbnnwYdzRiTiOM5yvfe/yg7Ee7W8lHXyL6nEgIh5cuWf5vZywzT4KMs67g6x+598mMh//sy5y3fAM2PcqQuenxXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f5d4a40201e90b31c253505480b6ddb9/2aaaf/about-logo-blueprint.webp 160w", "/static/f5d4a40201e90b31c253505480b6ddb9/85e47/about-logo-blueprint.webp 320w", "/static/f5d4a40201e90b31c253505480b6ddb9/75198/about-logo-blueprint.webp 640w", "/static/f5d4a40201e90b31c253505480b6ddb9/691bc/about-logo-blueprint.webp 960w", "/static/f5d4a40201e90b31c253505480b6ddb9/223e5/about-logo-blueprint.webp 1280w", "/static/f5d4a40201e90b31c253505480b6ddb9/c695b/about-logo-blueprint.webp 1328w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f5d4a40201e90b31c253505480b6ddb9/69538/about-logo-blueprint.png 160w", "/static/f5d4a40201e90b31c253505480b6ddb9/72799/about-logo-blueprint.png 320w", "/static/f5d4a40201e90b31c253505480b6ddb9/6af66/about-logo-blueprint.png 640w", "/static/f5d4a40201e90b31c253505480b6ddb9/d9199/about-logo-blueprint.png 960w", "/static/f5d4a40201e90b31c253505480b6ddb9/21b4d/about-logo-blueprint.png 1280w", "/static/f5d4a40201e90b31c253505480b6ddb9/6569d/about-logo-blueprint.png 1328w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f5d4a40201e90b31c253505480b6ddb9/6af66/about-logo-blueprint.png",
              "alt": "Spindleのロゴの構図。シンボルマークは1:1414の比率になっている。",
              "title": "Spindleのロゴの構図。シンボルマークは1:1414の比率になっている。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "spindle-motion-logo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#spindle-motion-logo",
        "aria-label": "spindle motion logo permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Spindle Motion Logo`}</h2>
    <figure>
  <video src="/video/motion_logo.mp4" controls width="640" className="layout-motion-logo-movie"></video>
    </figure>
    <p>{`"2重らせん構造"をモチーフとした"Ameba（緑）"と"Spindle（黄緑）"のDNAが細胞分裂を繰り返し、柔軟に変化、拡張し続ける意思を表現しています。`}</p>
    <h2 {...{
      "id": "利用規約",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84",
        "aria-label": "利用規約 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`利用規約`}</h2>
    <p>{`Spindleの利用は`}<a parentName="p" {...{
        "href": "https://helps.ameba.jp/rules/post_104.html"
      }}>{`Ameba利用規約`}</a>{`で定められた内容に準ずるものとします。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      